<template>
  <div ref="body" class="body">
    
    <div class="bodyHeight">
      <custom-header active="home"></custom-header>
      
      <!-- 移动 -->
      <div class="date text-center text-bold">
        <span class="block">2025.3.16-19</span>
        <span class="block">深圳国际会展中心</span>
      </div>
      <!-- <div class="sign-btn-box1">
        <a href="https://exhibitor.szcreativeweek.com/admin/join" target="_blank" class="button text-medium" rel="noopener noreferrer">我要参展</a>
        <a href="/register" class="button reg-btn text-medium" rel="">预先登记</a>
      </div> -->
      
      <!-- banner -->
      <div class="banner-container relative">
        <van-swipe ref="banner" :autoplay="5000" class="fill">
          <van-swipe-item v-for="(item, idx) in banners" :key="idx">
            <div class="fill" >
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" style="width: 100%; height: auto;">
              </a>
              <img v-else :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" style="width: 100%; height: auto;">
            </div>
          </van-swipe-item>
          <template v-if="banners.length > 1" #indicator>
            <div class="custom-banner-indicator">
              <van-icon name="down" class="btn-box left" @click="turnLeft('banner')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('banner')" />
            </div>
          </template>
        </van-swipe>
        
        <!-- <div class="sign-btn-box absolute">
          <a href="https://exhibitor.szcreativeweek.com/admin/join" target="_blank" class="button text-medium" rel="noopener noreferrer">我要参展</a>
          <a href="/register" class="button reg-btn text-medium" rel="">预先登记</a>
        </div> -->
      </div>
    </div>
    
    <!-- 展会介绍 -->
    <div class="intro-section flex flex-wrap">
      <div class="intro">
        <h1 class="text-bold">深圳国际家具展</h1>
        <div class="">深圳家居设计周暨深圳国际家具展，创立于1996年，坚持“设计导向、潮流引领、持续创新”，以设计为纽带，与城市文化共融的深圳国际家具展，日益成为家具和设计界认识深圳的窗口，也成为“国际设计资源与中国制造连接”及“中国制造寻找国际、国内市场”的战略平台，以高标准驱动中国家居产业高质量发展。</div>
      </div>
      <div class="intro-img flex justify-between">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/02.jpg" alt="" class="intro-img-left">
        <img src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/01.jpg" alt="" class="intro-img-right">
      </div>
    </div>
    
    <!-- 家具展商 / 展商阵容 -->
    <div class="lineup-box section">
      <div class="section-container relative" style="width: 100%;">
        <div class="title">家具展商</div>
        <!-- pc -->
        <van-swipe ref="exh" class="scroll-contianer" :loop="false" :width="500" @change="exhChange" :show-indicators="false">
          <van-swipe-item v-for="(item, idx) in lineupList" :key="idx">
            <div class="item1 text-center">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius1">
                <div class="item-title">{{item.title}}</div>
              </a>
              <template v-else>
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius1">
                <div class="item-title">{{item.title}}</div>
              </template>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box1 left" @click="turnLeft('exh')" />
              <van-icon name="down" class="btn-box1 right" @click="turnRight('exh')" />
            </div>
          </template>
        </van-swipe>
        <!-- 移动 -->
        <van-swipe class="scroll-contianer1" :loop="false" :show-indicators="false">
          <van-swipe-item v-for="(item, idx) in lineupList" :key="idx">
            <div class="item1 text-center">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius4">
                <div class="item-title">{{item.title}}</div>
              </a>
              <template v-else>
                <img :src="item.bannerPicture + '?x-oss-process=image/resize,p_50'" alt="" class="exh-img radius4">
                <div class="item-title">{{item.title}}</div>
              </template>
            </div>
          </van-swipe-item>
        </van-swipe>
        
      </div>
    </div>
    
    <!-- 展会特色板块 -->
    <div class="section bg-white">
      <div class="section-container relative" style="width: 100%;">
        <div class="title">展会特色板块</div>
        <!-- pc -->
        <van-swipe ref="theme-exh" class="scroll-contianer" :loop="false" :width="500" @change="themeChange" :show-indicators="false">
          <van-swipe-item v-for="item in themes" :key="item.partsId">
            <!-- <a class="item1 text-center" :href="'/theme?id=' + item.partsId">
              <img :src="item.img + '?x-oss-process=image/resize,p_50'" :alt="item.partsName" class="exh-img radius1"> 
            </a> -->
            <a class="item1 text-center">
              <img :src="item.img + '?x-oss-process=image/resize,p_50'" :alt="item.partsName" class="exh-img radius1"> 
            </a>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box left" @click="turnLeft('theme-exh')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('theme-exh')" />
            </div>
          </template>
        </van-swipe>
        <!-- 移动 -->
        <van-swipe class="scroll-contianer1" :loop="false" :autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="item in themes" :key="item.partsId">
            <!-- <a class="item1 text-center" :href="'/theme?id=' + item.partsId">
              <img :src="item.img + '?x-oss-process=image/resize,p_50'" :alt="item.partsName" class="exh-img radius4">
            </a> -->
            <a class="item1 text-center">
              <img :src="item.img + '?x-oss-process=image/resize,p_50'" :alt="item.partsName" class="exh-img radius4">
            </a>
          </van-swipe-item>
        </van-swipe>
        
      </div>
    </div>
    
    <!-- 展会活动 -->
    <div class="section bg-white">
      <div class="section-container relative" style="width: 100%;">
        <div class="title-box">
          <div class="title">展会活动</div>
        </div>
        <div class="title-box1">
          <div class="title">展会活动</div>
        </div>
        <!-- pc -->
        <van-swipe ref="active" class="scroll-contianer" :loop="false" :width="500" @change="activeChange" :show-indicators="false">
          <van-swipe-item v-for="item in activities" :key="item.id">
            <div class="item text-center">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.activityPic + '?x-oss-process=image/resize,p_50'" :alt="item.activityName" class="exh-img">
                <div class="intro-box2">
                  <h2>{{item.activityName}}</h2>
                  <p class="intro">{{item.activityIntroduce}}</p>
                </div>
              </a>
              <template v-else>
                <img :src="item.activityPic + '?x-oss-process=image/resize,p_50'" :alt="item.activityName" class="exh-img">
                <div class="intro-box2">
                  <h2>{{item.activityName}}</h2>
                  <p class="intro">{{item.activityIntroduce}}</p>
                </div>
              </template>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box left" @click="turnLeft('active')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('active')" />
            </div>
          </template>
        </van-swipe>
        <!-- 移动 -->
        <van-swipe class="scroll-contianer1" :loop="false" :autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="item in activities" :key="item.id">
            <div class="item text-center" @click="toActiveDetail(item.id)">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.activityPic + '?x-oss-process=image/resize,p_50'" :alt="item.activityName" class="exh-img">
                <div class="intro-box2">
                  <h2>{{item.activityName}}</h2>
                  <p class="intro">{{item.activityIntroduce}}</p>
                </div>
              </a>
              <template v-else>
                <img :src="item.activityPic + '?x-oss-process=image/resize,p_50'" :alt="item.activityName" class="exh-img">
                <div class="intro-box2">
                  <h2>{{item.activityName}}</h2>
                  <p class="intro">{{item.activityIntroduce}}</p>
                </div>
              </template>
            </div>
          </van-swipe-item>
        </van-swipe>
        
      </div>
      <a class="more block" href="/activity">了解更多</a>
    </div>
    
    <!-- 高峰论坛 -->
    <div class="section bg-white">
      <div class="section-container relative" style="width: 100%;">
        <div class="title-box">
          <div class="title">高峰论坛</div>
        </div>
        <div class="title-box1">
          <div class="title">高峰论坛</div>
        </div>
        <!-- pc -->
        <van-swipe ref="bbs" class="scroll-contianer" :loop="false" :width="500" @change="bbsChange" :show-indicators="false">
          <van-swipe-item v-for="item in forums" :key="item.forumOfficialId">
            <div class="item1 text-center">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.forumCover + '?x-oss-process=image/resize,p_50'" :alt="item.forumName" class="exh-img" @click="toForumDetail(item.forumOfficialId)">
                <div class="intro-box2">
                  <h2>{{item.forumName}}</h2>
                  <p class="intro">{{item.introduce}}</p>
                </div>
              </a>
              <template v-else>
                <img :src="item.forumCover + '?x-oss-process=image/resize,p_50'" :alt="item.forumName" class="exh-img" @click="toForumDetail(item.forumOfficialId)">
                <div class="intro-box2">
                  <h2>{{item.forumName}}</h2>
                  <p class="intro">{{item.introduce}}</p>
                </div>
              </template>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box left" @click="turnLeft('bbs')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('bbs')" />
            </div>
          </template>
        </van-swipe>
        <!-- 移动 -->
        <van-swipe class="scroll-contianer1" :loop="false" :autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="item in forums" :key="item.forumOfficialId">
            <div class="item1 text-center">
              <a v-if="item.url" target="_blank" :href="item.url" rel="noopener noreferrer">
                <img :src="item.forumCover + '?x-oss-process=image/resize,p_50'" :alt="item.forumName" class="exh-img" @click="toForumDetail(item.forumOfficialId)">
                <div class="intro-box1">
                  <h2>{{item.forumName}}</h2>
                  <p class="intro">{{item.introduce}}</p>
                </div>
              </a>
              <template v-else>
                <img :src="item.forumCover + '?x-oss-process=image/resize,p_50'" :alt="item.forumName" class="exh-img" @click="toForumDetail(item.forumOfficialId)">
                <div class="intro-box1">
                  <h2>{{item.forumName}}</h2>
                  <p class="intro">{{item.introduce}}</p>
                </div>
              </template>
              
            </div>
          </van-swipe-item>
        </van-swipe>
        
      </div>
      <a class="more block" href="/bbs">了解更多</a>
    </div>
    
    <!-- 论坛嘉宾 -->
    <div class="section bg-white">
      <div class="section-container" style="width: 100%;">
        <div class="title-box">
          <div class="title">论坛嘉宾</div>
        </div>
        <div class="title-box1">
          <div class="title">论坛嘉宾</div>
        </div>
        <!-- pc -->
        <van-swipe ref="guest" class="scroll-contianer" :loop="false" :width="645" @change="guestChange" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in forumGuests" :key="index">
            <div class="item2 flex-wrap">
              <div class="guest-img relative" v-for="(guest) in item" :key="guest.guestId">
                <img :src="guest.img + '?x-oss-process=image/resize,p_50'" :alt="guest.guestName" class="fill">
                <div class="guest-name text-cut">{{guest.guestName}}</div>
              </div>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box left" @click="turnLeft('guest')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('guest')" />
            </div>
          </template>
        </van-swipe>
        <!-- 移动 -->
        <van-swipe class="scroll-contianer1" :loop="false" :autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="(item, index) in forumGuests" :key="index">
            <div class="item2 flex-wrap">
              <div class="guest-img relative" v-for="(guest) in item" :key="guest.guestId">
                <img :src="guest.img + '?x-oss-process=image/resize,p_50'" :alt="guest.guestName" class="fill">
                <div class="guest-name">{{guest.guestName}}</div>
              </div>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      <a class="more block" href="/guest">了解更多</a>
    </div>
    
    <!-- 展会平面图 -->
    <!-- <div class="section bg-white flex flex-direction align-center">
      <div class="title-box">
        <div class="map-title text-center">展会平面图</div>
        <img :src="'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/map.jpg'" alt="guide map" class="block" style="width: 80%; height: auto; margin: 0 auto;">
      </div>
      <div class="title-box1">
        <div class="title">展会平面图</div>
        <img :src="'https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/cn/img/map.jpg'" alt="guide map" style="width: 100%; height: auto;">
      </div>
    </div> -->
    
    <!-- 资讯 -->
    <div class="section bg-white" >
      <div class="tabs-bar flex justify-center relative">
        <div class="tabs flex">
          <div class="tab" @click="switchNews('expo')">
            <span>展会资讯</span>
            <div :class="['cur', activeName === 'expo' ? 'block' : 'hide']">&nbsp;</div>
          </div>
          <div class="tab" @click="switchNews('news')">
            <span>品牌资讯</span>
            <div :class="['cur', activeName === 'news' ? 'block' : 'hide']">&nbsp;</div>
          </div>
        </div>
      </div>
      
      <!-- 展会资讯 -->
      <div v-if="activeName === 'expo'" class="relative flex align-center justify-center" style="width: 100%;">
        <!-- pc -->
        <van-swipe ref="exh-news" class="scroll-contianer" :loop="false" :width="560" @change="exhNewsChange" :show-indicators="false">
          <van-swipe-item v-for="(item) in newsList1" :key="item.newsId">
            <div class="item text-center">
              <a :href="'/newsDetail?id=' + item.newsId">
                <img :src="item.newsCover + '?x-oss-process=image/resize,p_50'" alt="" class="news-img">
                <div class="intro-box2">
                  <h2>{{item.newsTitle}}</h2>
                  <p class="intro">{{item.introduce}}</p>
                </div>
              </a>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box left" @click="turnLeft('exh-news')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('exh-news')" />
            </div>
          </template>
        </van-swipe>
        <!-- 移动 -->
        <van-swipe class="scroll-contianer0" :loop="false" :autoplay="4000" :show-indicators="false">
          <van-swipe-item v-for="(item) in newsList1" :key="item.newsId">
            <div class="item text-center">
              <a :href="'/newsDetail?id=' + item.newsId">
                <img :src="item.newsCover + '?x-oss-process=image/resize,p_50'" alt="" class="news-img">
                <h2 class="text-cut">{{item.newsTitle}}</h2>
              </a>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
    
      <!-- 品牌资讯 -->
      <div v-if="activeName === 'news'" class="relative flex align-center justify-center" style="width: 100%;">
        <!-- pc -->
        <van-swipe ref="brand-news" class="scroll-contianer2" :loop="false" :width="500" :show-indicators="false">
          <van-swipe-item v-for="(item) in newsList2" :key="item.newsId">
            <div class="item text-center">
              <a :href="'/newsDetail?id=' + item.newsId">
                <img :src="item.newsCover + '?x-oss-process=image/resize,p_50'" alt="" class="news-img">
                <div class="intro-box">
                  <h2>{{item.newsTitle}}</h2>
                </div>
              </a>
            </div>
          </van-swipe-item>
          <template #indicator>
            <div class="turn-btn-box absolute flex justify-between">
              <van-icon name="down" class="btn-box left" @click="turnLeft('brand-news')" />
              <van-icon name="down" class="btn-box right" @click="turnRight('brand-news')" />
            </div>
          </template>
        </van-swipe>
        <!-- 移动 -->
        <van-swipe class="scroll-contianer3" :loop="false" :show-indicators="false">
          <van-swipe-item v-for="(item) in newsList2" :key="item.newsId">
            <div class="item text-center">
              <a :href="'/newsDetail?id=' + item.newsId">
                <img :src="item.newsCover + '?x-oss-process=image/resize,p_50'" alt="" class="news-img">
                <h2 class="text-cut">{{item.newsTitle}}</h2>
              </a>
            </div>
          </van-swipe-item>
        </van-swipe>
      </div>
      
      <a class="more more1 block" :href="'/' + activeName">了解更多</a>
    </div>
    
    <!-- 展会视频 -->
    <div class="section bg-white" >
      <div class="relative flex flex-direction align-center justify-center" style="width: 100%;">
        <div class="title-box">
          <div class="map-title text-center">展会视频</div>
        </div>
        <div class="title-box1">
          <div class="title">展会视频</div>
        </div>
        <div class="video-container">
          <video id="my-video" controls class="fill">
            <source src="https://designer-wxapp.oss-cn-shenzhen.aliyuncs.com/2023SZCW.mp4" type="video/mp4">
          </video>
        </div>
      </div>
    </div>
    
    <!-- 开展倒计时 -->
    <!-- <div class="section" style="background-image: linear-gradient(-180deg, #002230, #0d0d0d);"> -->
    <div class="section">
      
      <div class="downcount-box">
        <span class="noties block text-center">距40届深圳家居设计周暨深圳国际家具展开展还有</span>
        <div class="day">
          <span class="downcount-day">{{day}}</span>
          <span>天</span>
        </div>
        
      </div>
      
      <div class="sign-btn-box2 flex">
        <a href="https://exhibitor.szcreativeweek.com/admin/join" target="_blank" class="button text-medium" rel="noopener noreferrer">我要参展</a>
        <a href="/register" class="button reg-btn" rel="">预先登记</a>
      </div>
      
    </div>
    
    <custom-footer :friendLink="friendLink"></custom-footer>
    
    <!-- 回到顶部 -->
    <scroll-top @scrollTop="scrollTop"></scroll-top>
    
  </div>
</template>

<script>
import { Swipe, SwipeItem, Icon } from 'vant';
import customHeader from "./components/head.vue";
import customFooter from "./components/foot.vue";
import scrollTop from "./components/scrollTop.vue";
import commonMixin from './commonMixin.js';
import { getHomeBanner, getNewsList, getThemeList, getActivityList, getForumList, getForumGuest, getExternalList } from '@/api/index.js';

export default {
  components: {
    'custom-header': customHeader,
    'custom-footer': customFooter,
    'scroll-top': scrollTop,
    [Icon.name]: Icon,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
  },
  mixins: [commonMixin],
  // SEO: 设置页面keyWords、description
  metaInfo: {
    // title: pageTitle, // 页面标题
    meta: [
      { name: 'keyWords', content: '深圳家具展，家具展，家居设计，家具展商，家居论坛，设计周，家居设计周' },
      { name: 'description', content: '深圳家居设计周暨40届深圳国际家具展，将于2025年3月16-19日在深圳国际会展中心盛大启幕，是目前国内唯一横向打通住宅、建筑、室内、家具、软装、灯光及工作环境全产业链的产业创新与教育分享展。' },
      // { name: 'viewport', content: 'width=device-width, user-scalable=yes, initial-scale=1.0, maximum-scale=3.0, minimum-scale=1.0' },
    ],
    // link: [
    //   {rel: 'icon', href: '/favicon.ico'},
    //   {rel: 'stylesheet', href: 'https://xxxxxxx.com/style.css', type: 'text/css'},
    // ],
  },
  data() {
    return {
      activeName: 'expo',
      day: 1,
      banners: [],
      lineupList: [],
      themes: [],
      activities: [],
      forums: [],
      newsList1: [],
      newsList2: [],
      forumGuests: [],
      // friendLink: [],
    }
  },
  created() {
    this.getDay();
    this.getBanners(); // 获取banner
    this.getLineup(); // 获取家具展商/展商阵容
    this.getThemes(); // 获取展会特色板块
    // this.getFriendLink();
    
    this.getActivities(); // 获取展会活动
    this.getForumGuests(); // 获取论坛特邀嘉宾
    this.getForums(); // 获取高峰论坛
    this.getNews('expo'); // 获取展会资讯
    this.getNews('news'); // 获取品牌资讯
  },
  destroyed () {
    let videoElement = document.querySelector('#my-video');
    if (videoElement) {
      // 销毁 VIDEO 元素
      videoElement.pause(); // 停止播放
      videoElement.removeAttribute('src'); // 清空srcs属性来停止加载
      videoElement.load(); // 调用 load() 方法，以确保所有相关数据都被卸载。
      // 将 VIDEO 元素的事件监听器设为 null，以避免内存泄漏。
      videoElement.oncanplaythrough = null;
      videoElement.onerror = null;
      videoElement.remove();
      videoElement = null; // 最后将 VIDEO 元素赋值为 null，以释放其占用的内存。
    }
  },
  methods: {
    // 获取banner
    getBanners() {
      getHomeBanner({bannerType: 'WEBSITE'}).then(res => {
        if (res.resultStatus) {
          this.banners = res.resultData;
        }
      })
    },
    // 获取家具展商/展商阵容
    getLineup() {
      getHomeBanner({bannerType: 'LINEUP'}).then((res) => {
        if (res.resultStatus) {
          this.lineupList = res.resultData;
        }
      });
    },
    // 获取展会特色板块
    getThemes() {
      getThemeList().then(res => {
        this.themes = (res.resultData || []).filter(e => e.partsId != 8)
      })
    },
    // 获取展会活动
    getActivities() {
      let params = {
        pageNo: 1,
        pageSize: 10,
      };
      getActivityList(params).then(res => {
        this.activities = res.resultData.records || []
      })
    },
    // 获取高峰论坛
    getForums() {
      let params = {
        pageNo: 1,
        pageSize: 10,
      };
      getForumList(params).then(res => {
        this.forums = res.resultData.records.map( e => ({
          forumOfficialId: e.forumOfficialId,
          forumCover: e.forumCover,
          forumName: e.forumName,
          introduce: e.introduce,
        }))
      })
    },
    // 获取论坛特邀嘉宾
    getForumGuests() {
      let params = {
        categoryId: '',
        keywords: '',
        pageNo: 1,
        pageSize: 100,
      };
      getForumGuest(params).then(res => {
        let records = res.resultData.records || [];
        let arr = [];
        if (records.length > 0) {
          let len = Math.ceil(records.length / 6);
          for (let i = 0; i < len; i++) {
            arr.push(records.slice(i * 6, i * 6 + 6))
          }
        }
        this.forumGuests = arr;
      })
    },
    switchNews(name) {
      this.activeName = name;
      this.getNews(name);
    },
    // 获取展会资讯、品牌资讯
    getNews(name) {
      let params = {
        keyword: this.keyword,
        pageNo: 1,
        pageSize: 5,
        type: name == 'expo' ? 1 : 2, // 0-逛展小程序展会资讯, 1-官网展会资讯, 2-官网论坛资讯
        recommend: 1, // 是否推荐, 1-显示在首页, 0-不推荐, 不传参-所有
        random: false,
      };
      
      getNewsList(params).then(res => {
        let {records = [], total = 0, pages = 1 } = res.resultData;
        if (name == 'expo') {
          this.newsList1 = records;
        } else{
          this.newsList2 = records;
        }
      })
    },
    // 链接跳转
    urlLink(url) {
      url && (window.location = url);
    },
    exhChange(e) {
      console.log(e, this.lineupList.length - 2);
      if (e == this.lineupList.length - 2) {
        this.swipeToFirst('exh');
      }
    },
    themeChange(e) {
      if (e == 7) {
        // this.swipeToFirst('theme-exh');
      }
    },
    activeChange(e) {
      if (e == 4) {
        // this.swipeToFirst('active');
      }
    },
    bbsChange(e) {
      if (e == 6) {
        // this.swipeToFirst('bbs');
      }
    },
    exhNewsChange(e) {
      if (e == 1) {
        // this.swipeToFirst('exh-news');
      }
    },
    guestChange(e) {
      if (e == 1) {
        // this.swipeToFirst('guest');
      }
    },
    // 切换到轮播第一项
    swipeToFirst(name) {
      setTimeout(() => {
        this.$refs[name].swipeTo(0);
      }, 5000);
    },
    getDay() {
      let start = new Date(), end = new Date('2025-03-16');
      this.day = parseInt((end.getTime() - start.getTime()) / (1000 * 60 * 60 * 24)) + 1;
    },
    turnLeft(key) {
      this.$refs[key].prev();
    },
    turnRight(key) {
      this.$refs[key].next();
    },
    // 跳转到活动详情页
    toActiveDetail(id) {
      // this.$router.push({
      //   path: '/activityDetail',
      //   query: {
      //     id
      //   }
      // })
    },
    // 跳转到论坛详情页
    toForumDetail(id) {
      // this.$router.push({
      //   path: '/bbsDetail',
      //   query: {
      //     id
      //   }
      // })
    },
    // 获取友情链接列表
    getFriendLink() {
      getExternalList().then(res => {
        let list = res.resultData || [];
        this.friendLink = list;
      })
    },
  },
}
</script>

<style scoped lang="less">
.radius6Px {
  border-radius: 6Px!important;
}

@purplish-red: #E4007F; // 紫红色
@cyan: #00ABA5; // 紫红色
  
// 移动端
@media screen and (max-width: 1023px) {
  
  .date {
    border-top: 1px solid #eee;
    padding: 10px 0;
  }
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
    
    .custom-banner-indicator {
      display: none;
    }
    
  }

  .sign-btn-box {
    display: none;
  }
  .sign-btn-box2 {
    font-family: 'SourceHanSansCN-Light';
    justify-content: center;
    margin-bottom: 15px;
    
    .button {
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 140px;
      padding: 10px 0;
      text-align: center;
      margin: 6px 15px;
      font-size: 14px;
      border-radius: 4px;
      cursor: pointer;
    }
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }
  
  .sign-btn-box1 {
    font-family: 'SourceHanSansCN-Light';
    display: flex;
    justify-content: center;
    
    .button {
      width: 140px;
      height: 30px;
      line-height: 30px;
      border: none;
      background-color: @purplish-red;
      color: #fff;
      margin: 20px 8px;
      font-size: 12px;
      text-align: center;
      border-radius: 4px;
      cursor: pointer;
    }
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }

  .intro-section-en,
  .intro-section {
    width: 340px;
    margin: 10px auto 20px;
    
    
    .intro {
      width: 100%;
      font-size: 14px;
      line-height: 24px;
      text-align: justify;
      padding-top: 20px;
      color: #5C5C5C;
      
      h1 {
        font-size: 20px;
        margin-bottom: 15px;
        text-align: center;
        color: #060211;
      }
    }
    
    .intro-img {
      width: 100%;
      height: 120px;
      margin: 20px 0;
      
      .intro-img-right {
        width: auto;
        height: 100%;
        border-radius: 2px;
      }
      
      .intro-img-left {
        width: auto;
        height: 100%;
        border-radius: 2px;
      }
    }
    
  }

  .section {
    padding: 24px 20px;
    
    .section-container {
      
    }
    
    .title-box {
      display: none;
    }
    .title {
      width: 100%;
      margin-bottom: 10px;
      font-size: 20px;
      font-family: 'SourceHanSansCN-Bold';
      text-align: center;
    }
    
    .more {
      margin: 15px auto;
      width: 90px;
      line-height: 24px;
      font-size: 12px;
      text-align: center;
      color: #fff;
      background-color: @cyan;
      border-radius: 2px;
      cursor: pointer;
    }
    
    .turn-btn-box ,
    .scroll-contianer,
    .scroll-contianer2 {
      display: none;
    }
    
    .scroll-contianer1 {
      width: 100%;
      
      .item,
      .item1,
      .item2 {
        display: inline-block;
        width: 100%;
        
        .item-title {
          position: absolute;
          left: 0;
          bottom: 0;
          width: 100%;
          line-height: 36px;
          font-size: 18px;
          color: #fff;
          text-align: center;
          font-family: 'SourceHanSansCN-Bold';
          letter-spacing: 2Px;
        }
        
        .exh-img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 4px 4px 0 0;
        }
        
        .intro-box1,
        .intro-box2,
        .intro-box3 {
          width: 100%;
          overflow: hidden;
          border-radius: 0 0 4px 4px;
          padding: 10px 0;
          color: #060211;
          
          h2 {
            padding: 0 8px;
            margin-bottom: 6px;
            font-family: 'SourceHanSansCN-Medium';
            font-size: 14px;
            text-align: left;
          }
        }
        
        .intro-box1 {
          background-color: #fff;
        }
        .intro-box2 {
          background-color: #FAFAFA;
        }
        .intro-box3 {
          background-color: #060211;
          color: #fff;
        }
        
        
        .intro,
        .intro1 {
          display: block;
          width: 100%;
          box-sizing: border-box;
          padding: 0 8px;
          margin-top: 2px;
          font-size: 14px;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display:-webkit-box;
          -webkit-line-clamp:2;
          -webkit-box-orient:vertical;
        }
        
        .intro1 {
          height: 16px;
          
          h2 {
            padding: 2px 5px 0;
            line-height: 8px;
            font-weight: bold;
            font-size: 3.2px;
            text-align: left;
          }
        }
      }
      .item1 {
        position: relative;
        height: auto;
      }
      
      .item2 {
        display: flex;
        height: auto;
        padding: 0;
      }
      .item2:first-child {
        margin-left: 4px;
      }
      
    }
    
    .guest-img {
      width: 100px;
      height: 100px;
      margin: 0 10px 10px 0;
      border-radius: 4px;
      overflow: hidden;
    }
    
    .guest-name {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      color: #fff;
      line-height: 30Px;
      border-radius: 0 0 4px 4px;
      padding: 0 5px;
      width: 100%;
      font-size: 12px;
      text-align: center;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
    
    .play-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20px;
      height: 7px;
      margin-left: 6px;
      padding: 0 4px;
      border: none;
      font-size: 3.2px;
      border-radius: 1.2px;
    }
    
  }
  
  .tabs-bar {
    margin: 0 0 20px;
    
    .tabs {
      
      .tab {
        height: 24px;
        font-size: 16px;
        font-family: 'SourceHanSansCN-Bold';
        margin: 0 10px;
        
        .cur {
          width: 100%;
          height: 3px;
          margin-top: 2px;
          background-color: #060211;
          border-radius: 2px;
        }
      }
      
    }
    
  }
  

  .scroll-contianer0,
  .scroll-contianer3 {
    width: 100%;
    border-radius: 1.2px;
    
    .item {
      display: inline-block;
      width: 100%;
      // height: auto;
      // overflow: hidden;
      
      .news-img {
        display: block;
        width: 100%;
        height: auto;
        border-radius: 4px;
      }
        
      h2 {
        width: 100%;
        padding: 5px;
        line-height: 20px;
        font-family: 'SourceHanSansCN-Bold';
        font-size: 14px;
        text-align: left;
      }
      
    }
    
  }
  
  .video-container {
    margin-top: 6px;
    width: 100%;
    height: 200px;
    background-color: #fff;
    border-radius: 1px;
    overflow: hidden;
  }
  
  .downcount-box {
    margin-bottom: 10px;
    text-align: center;
    
    .noties {
      margin: 0 auto;
      width: 100%;
      font-size: 16px;
      font-family: 'SourceHanSansCN-Bold';
    }
    .day {
      font-family: 'SourceHanSansCN-Bold';
      font-size: 20px;
      margin: 10px auto;
      
      .downcount-day {
        color: @cyan;
        padding: 0 4px;
        font-size: 36px;
        font-family: 'SourceHanSansCN-Bold';
        padding-right: 15px;
      }
      
    }
  }
  
}

// PC端
@media screen and (min-width: 1024px) {
  
  .date,
  .sign-btn-box1 {
    display: none;
  }
  
  .banner-container {
    width: 100%;
    
    .banner {
      width: 100%;
      height: 100%;
      background-position: center;
      background-repeat: no-repeat;
      background-size: 100% auto;
    }
    
    .custom-banner-indicator {
      position: absolute;
      right: 6px;
      bottom: 6px;
      padding: 1px;
      
      .btn-box {
        margin: 0 1px;
        padding: 1px;
        background: rgba(255, 255, 255, 0.3);
        border-radius: 1px;
        font-size: 8px;
        
        &.left {
          transform: rotate(90deg);
        }
        &.right {
          transform: rotate(-90deg);
        }
      }
    }
    
  }
  
  .sign-btn-box {
    right: 5%;
    top: 33%;
    z-index: 5;
    
    .button {
      font-family: 'SourceHanSansCN-Medium';
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 50px;
      padding: 3px 0;
      margin: 6px 0;
      font-size: 4px;
      text-align: center;
      border-radius: 1.2px;
      cursor: pointer;
    }
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }
  
  .sign-btn-box2 {
    justify-content: center;
    
    .button {
      font-family: 'SourceHanSansCN-Medium';
      display: block;
      background-color: @purplish-red;
      color: #fff;
      border: none;
      width: 50px;
      padding: 4px 0;
      margin: 6px 10px;
      font-size: 4px;
      text-align: center;
      border-radius: 1.2px;
      cursor: pointer;
    }
    
    .reg-btn {
      background-color: @cyan;
      color: #fff;
    }
  }
  
  .intro-section {
    width: 300px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    
    .intro {
      width: 70px;
      font-size: 3.5px;
      line-height: 1.6em;
      text-align: justify;
      padding-top: 5px;
      margin-right: 30px;
      
      h1 {
        font-size: 8px;
        margin-bottom: 10px;
      }
    }
    
    .intro-img {
      width: auto;
      height: 70px;
      
      .intro-img-right {
        width: auto;
        height: 100%;
        border-radius: 1px;
      }
      
      .intro-img-left {
        width: auto;
        height: 100%;
        border-radius: 1px;
        margin-right: 5px;
      }
    }
    
  }
  
  .intro-section-en {
    width: 350px;
    margin: 30px auto;
    display: flex;
    justify-content: center;
    
    .intro {
      width: 120px;
      font-size: 3.5px;
      line-height: 1.6em;
      text-align: justify;
      margin-right: 10px;
      
      h1 {
        font-size: 5px;
        margin-bottom: 5px;
      }
    }
    
    .intro-img {
      width: auto;
      height: 75px;
      
      .intro-img-right {
        width: auto;
        height: 100%;
        border-radius: 1px;
      }
      
      .intro-img-left {
        width: auto;
        height: 100%;
        border-radius: 1px;
        margin-right: 5px;
      }
    }
    
  }
  
  .section {
    padding: 24px 20px;
    
    .section-container {
      display: flex;
      // justify-content: center;
    }
    
    .title-box1 {
      display: none;
    }
    .more {
      margin: 10px  0 10px 48px;
      width: 36px;
      line-height: 10px;
      font-size: 4px;
      text-align: center;
      color: #fff;
      background-color: @cyan;
      border-radius: 1px;
      cursor: pointer;
    }
    .more1 {
      margin: 10px auto;
    }
    
    .title-box {
      .map-title {
        font-size: 6px;
        font-family: 'SourceHanSansCN-Bold';
      }
    }
    
    .video-title {
      width: 24px;
      font-size: 5px;
      font-weight: bold;
    }
    
    .title {
      margin-right: 4px;
      width: 42px;
      font-size: 7px;
      font-family: 'SourceHanSansCN-Bold';
    }
    
    .scroll-contianer1 {
      display: none;
    }
    
    .scroll-contianer {
      width: 270px;
      border-radius: 1.2px;
      
      /deep/ .van-swipe-item {
        width: 100px!important;
      }
      
      .item,
      .item1,
      .item2 {
        display: inline-block;
        width: 100%;
        // height: 110px;
        padding: 0 2px;
        box-sizing: border-box;
        
        .item-title {
          position: absolute;
          left: 10px;
          bottom: 8px;
          font-size: 5px;
          color: #fff;
          font-weight: bold;
          letter-spacing: 2Px;
        }
        
        &:hover {
          .exh-img,
          .intro-box1,
          .intro-box2,
          .intro-box3 {
            box-shadow: 0 .1px 2px rgba(0, 0, 0, .1);
          }
        }
        
        .exh-img {
          display: block;
          width: 100%;
          height: auto;
          border-radius: 1px 1px 0 0;
        }
        
        .intro-box1,
        .intro-box2,
        .intro-box3 {
          width: 100%;
          height: 40px;
          overflow: hidden;
          box-sizing: border-box;
          border-radius: 0 0 1px 1px;
          padding-bottom: 6px;
          color: #060211;
          
          h2 {
            padding: 2px 5px 0;
            line-height: 8px;
            font-weight: bold;
            font-size: 3.2px;
            text-align: left;
          }
        }
        
        .intro-box1 {
          background-color: #fff;
        }
        .intro-box2 {
          background-color: #FAFAFA;
        }
        .intro-box3 {
          background-color: #060211;
          color: #fff;
        }
        
        
        .intro,
        .intro1 {
          display: block;
          width: 100%;
          height: 24px;
          box-sizing: border-box;
          padding: 0 5px;
          margin-top: 2px;
          line-height: 5px;
          font-size: 3px;
          text-align: justify;
          overflow: hidden;
        }
        
        .intro1 {
          height: 16px;
          
          h2 {
            padding: 2px 5px 0;
            line-height: 8px;
            font-weight: bold;
            font-size: 3.2px;
            text-align: left;
          }
        }
      }
      .item1 {
        position: relative;
        height: auto;
      }
      
      .item2 {
        display: flex;
        height: auto;
        padding: 0;
      }
      .item2:first-child {
        margin-left: 4px;
      }
      
      .turn-btn-box {
        display: none;
        right: 0;
        top: 50%;
        z-index: 1;
        transform: translateY(-5px);
        width: 100%;
        box-sizing: border-box;
        padding: 0 4px 0 6px;
        
        .btn-box,
        .btn-box1 {
          margin: 0 1px;
          padding: 1px;
          background: rgba(255, 255, 255, 0.7);
          border-radius: 40px;
          font-size: 7px;
          
          &.left {
            transform: rotate(90deg);
            float: left;
          }
          &.right {
            transform: rotate(-90deg);
            float: right;
          }
        }
        
        .btn-box1 {
          background-color: rgba(0, 0, 0, .6);
          color: #fff;
        }
      }
      
      &:hover {
        .turn-btn-box {
          display: block;
        }
      }
    }
    
    .guest-img {
      width: 46px;
      height: 46px;
      margin: 0 4px 4px 0;
      border-radius: 0.8px;
      overflow: hidden;
    }
    
    .guest-name {
      position: absolute;
      bottom: 0;
      background-color: rgba(0, 0, 0, .5);
      color: #fff;
      line-height: 10px;
      border-radius: 0 0 1px 1px;
      padding: 0 2px;
      width: 100%;
      font-size: 4px;
      text-align: center;
    }
    
    .play-btn {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 20px;
      height: 7px;
      margin-left: 6px;
      padding: 0 4px;
      border: none;
      font-size: 3.2px;
      border-radius: 1.2px;
    }
    
  }
  
  .tabs-bar {
    margin: 0 0 10px;
    
    .tabs {
      
      .tab {
        height: 10px;
        font-size: 6px;
        font-weight: bold;
        margin: 0 8px;
        cursor: pointer;
        
        .cur {
          width: 100%;
          height: 1px;
          margin-top: 1.2px;
          background-color: #060211;
          border-radius: 1px;
        }
      }
      
    }
    
    .news-more {
      font-size: 3.6px;
      padding-top: 2px;
      right: 12%;
      cursor: pointer;
      color: #999;
    }
  }
  
  .scroll-contianer0,
  .scroll-contianer3 {
    display: none;
  }
  
  .scroll-contianer2 {
    // width: 80%;
    width: 270px;
    border-radius: 1.2px;
    
    /deep/ .van-swipe-item {
      width: 112px!important;
    }
    
    .item {
      position: relative;
      display: inline-block;
      width: 108px;
      height: auto;
      overflow: hidden;
      
      &:hover {
        .intro-box {
          bottom: 0;
        }
      }
      
      .intro-box {
        position: absolute;
        left: 0;
        bottom: -20px;
        width: 100%;
        height: 20px;
        height: auto;
        box-sizing: border-box;
        border-radius: 0 0 1.2px 1.2px;
        color: #fff;
        background-color: rgba(0, 0, 0, .4);
        transition: bottom 0.2s;
        
        h2 {
          display: flex;
          align-items: center;
          padding: 4px;
          line-height: 6px;
          font-weight: bold;
          font-size: 3.2px;
          text-align: left;
        }
      }
      
    }
    
    .turn-btn-box {
      display: none;
      right: 0;
      top: 50%;
      z-index: 1;
      transform: translateY(-8px);
      width: 100%;
      box-sizing: border-box;
      padding: 0 1px;
      
      .btn-box {
        margin: 0 1px;
        padding: 1px;
        background-color: rgba(0, 0, 0, .6);
        color: #fff;
        border-radius: 20px;
        font-size: 7px;
        
        &.left {
          transform: rotate(90deg);
          float: left;
        }
        &.right {
          transform: rotate(-90deg);
          float: right;
        }
      }
      
    }
    
    &:hover {
      .turn-btn-box {
        display: block;
      }
    }
  }

  .news-img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 1.2px 1.2px 0 0;
  }
  
  .video-container {
    margin-top: 6px;
    width: 240px;
    height: 135px;
    background-color: #fff;
    border-radius: 1px;
    overflow: hidden;
  }
  
  .downcount-box {
    margin-bottom: 10px;
    
    .noties {
      font-size: 8px;
      font-family: 'SourceHanSansCN-Bold';
      margin-bottom: 5px;
    }
    .day {
      font-family: 'SourceHanSansCN-Bold';
      font-size: 12px;
      text-align: center;
      
      .downcount-day {
        color: @cyan;
        padding: 0 8px;
        font-size: 18px;
        font-family: 'SourceHanSansCN-Bold';
      }
      
    }
    
  }
  
}
  
</style>